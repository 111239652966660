import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const context = createContext<{
    queue: Record<string, number>,
    setQueue: Function;
}>({} as any)

export const ProgressBar: React.FC = ({children}) => {

    const [queue, setQueue] = useState<Record<string, number>>(() => ({}))

    useEffect(() => {
        const arr = Object.values(queue);
        const v = (arr.reduce((acc, value) => (acc) + (value || 0), 0) / arr.length) || 0
        if (!v) {
            NProgress.done()
        } else {
            NProgress.set(Math.min(0.2, v))
        }
    }, [queue])

    return <context.Provider value={{queue, setQueue}}>
        {children}
    </context.Provider>
}

type Progress = {
    id: string;
    set(v: number): void;
    done(): void;
}

export const useProgressBar = () => {
    const {setQueue, queue} = useContext(context);
    const [progress, setProgress] = useState<Progress>()

    const start = useCallback((init = 0.2, id = `${Date.now()}.${Math.random()}`): Progress => {
        progress?.done()
        const p = {
            id,
            set(v: number) {
                setQueue((q: any) => {
                    if (v) {
                        q[id] = v;
                    } else {
                        delete q[id]
                    }
                    return {...q}
                });
            },
            get() {
                return queue[id]
            },
            done() {
                setQueue((q: any) => {
                    delete q[id]
                    return {...q}
                });
            },
        }
        setProgress(p);
        p.set(init)
        return p;
    }, [progress, queue, setQueue])


    return {
        start,
        progress,
    }
}
