import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import ReactDOMServer from 'react-dom/server'
import useFetchJSON from "../hooks/useFetchJSON";
import get from 'lodash/get'
import type {Translations} from '../../public/i18n/translations'
import {NestedKeys} from "../utils/interfaces";


interface IContext {
    locale: string;
    setLocale: React.Dispatch<React.SetStateAction<string>>;
    t: (t: string, params?: Record<any, string | number | boolean | JSX.Element>) => string;
    tq: (t: string, quantitative: number) => string;
}

const Context = createContext<IContext>({} as IContext);

export default (function ({children, defaultLocale = 'en'}) {
    const [locale, setLocale] = useState(localStorage.locale || defaultLocale)
    const {data: translations} = useFetchJSON<Record<any, string>>(`/i18n/translations/${locale}.json`)

    const t = useCallback((path: string, params?: Record<any, any>) => {
        let r = get(translations || {}, path) || path;
        for (const [key, value] of Object.entries(params || {})) {
            if (React.isValidElement(value)) {
                r = r.replace(`:${key}`, ReactDOMServer.renderToString(<Self>{value}</Self>).replace(' <!-- -->', ''))
            } else {
                r = r.replace(`:${key}`, value.toString())
            }
        }
        return r;
    }, [defaultLocale, translations])

    const tq = useCallback((path: string, quantitative: number) => {
        let r = get(translations || {}, path) || path;
        for (const q of r.split('|')) {
            const specific = /^{([\d,]+)}\s?(.*)/.exec(q);
            const range = /^\[([\d*]+),([\d*]+)]\s?(.*)/.exec(q);

            let replace = false
            if (specific) {
                if (specific[1].split(',').includes(quantitative.toString())) {
                    replace = true;
                    r = specific[2]
                }
            } else if (range) {
                let min = +range[1] || -Infinity;
                let max = +range[2] || Infinity;
                if (min <= quantitative && quantitative <= max) {
                    replace = true;
                    r = range[3]
                }
            } else {
                return q.replace(':value', quantitative.toString());
            }
            if (replace) {
                return r.replace(':value', quantitative.toString());
            }
        }
        return r;
    }, [defaultLocale, translations])

    const Self = useMemo(() => (props: { children?: any }) => <Context.Provider
        value={{locale, setLocale, t, tq}}> {props.children || children}</Context.Provider>, [children, locale, t])
    return <Self/>
}) as React.FC<{ defaultLocale?: string }>

export const useLocale = () => useContext(Context)

type Props =
    { children: NestedKeys<(Translations)[keyof Translations]> | string; params?: Record<any, any>; quantitative?: never }
    | { children: NestedKeys<(Translations)[keyof Translations]> | string; params?: never; quantitative?: number }

export const Locale =
    ({children, params, quantitative}: Props) => {
        const {t, tq} = useLocale()
        return <>{typeof quantitative === 'number' ? tq(children as string, quantitative) : t?.(children as string, params)}</>
    }
