import {useState, useEffect} from "react";

interface Connection extends NetworkInformation {
    rtt?: any;
    saveData?: any;
    downLink?: any;
    downLinkMax?: any;
    effectiveType?: any;
}

interface State extends Omit<Connection, keyof EventTarget> {
    since?: Date;
    online: boolean;
}

function getNetworkConnection(): Connection {
    return (
        navigator.connection ||
        // @ts-ignore
        navigator.mozConnection ||
        // @ts-ignore
        navigator.webkitConnection ||
        null
    );
}

function getNetworkConnectionInfo(): Omit<Connection, keyof EventTarget> | undefined {
    const connection = getNetworkConnection();
    if (!connection) {
        return {} as Connection;
    }
    return {
        rtt: connection.rtt,
        type: connection.type,
        saveData: connection.saveData,
        downLink: connection.downLink,
        downLinkMax: connection.downLinkMax,
        effectiveType: connection.effectiveType,
    };
}

function useNetwork() {
    // @ts-ignore
    const [state, setState] = useState<State>(() => {
        return {
            since: undefined,
            online: navigator.onLine,
            ...getNetworkConnectionInfo(),
        };
    });
    useEffect(() => {
        const handleOnline = () => {
            // @ts-ignore
            setState((prevState) => ({
                ...prevState,
                online: true,
                since: new Date().toString(),
            }));
        };

        const handleOffline = () => {
            // @ts-ignore
            setState((prevState) => ({
                ...prevState,
                online: false,
                since: new Date().toString(),
            }));
        };

        const handleConnectionChange = () => {
            setState((prevState) => ({
                ...prevState,
                online: navigator.onLine,
                ...getNetworkConnectionInfo(),
            }));
        };
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        const connection = getNetworkConnection();
        connection?.addEventListener("change", handleConnectionChange);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
            connection?.removeEventListener("change", handleConnectionChange);
        };
    }, []);
    return state;
}

export default useNetwork;
