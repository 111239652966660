import useApiFetchV1 from "../useApiFetchV1";

export interface Customization {
    app_name: string;
    app_color_primary: string;
    app_color_secondary: string;
    app_color_accent: string;
    app_color_invoice_header_text: string;
    logo: string
}

export interface Settings {
    about: string;
    rules: string;
}

export interface Enums {
    setting_data_types: string[];
    setting_field_types: string[];
    item_status: string[];
    order_status: string[];
}

export interface Meta {
    domain: string;
    page_size: number;
    api_version: string;
    project_name: string;
    customization: Customization;
    settings: Settings;
    main_vendor_id?: any;
    orders_category_id: string;
    vendors_category_id: string;
    payment_methods_category_id: string;
    driver_category_id: string;
    customers_category_id: string;
    items_category_id: string;
    cards_category_id: string;
    schedules_category_id: string;
    images_category_id: string;
    files_category_id: string;
    references_category_id: string;
    enums: Enums;
    vendor?: any;
}


export default function () {
    return useApiFetchV1<Meta>('meta', undefined, {
        refreshInterval: 60000,
    })
}
