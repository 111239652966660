import Box from "@mui/material/Box";
import React, {useEffect, useMemo, useState} from "react";
import styled from "@mui/material/styles/styled";
import useNetwork from "../../hooks/useNetwork";
import {Locale} from "Providers/LocaleProvider";
import useSWR from "swr";
import {CircularProgress} from "@mui/material";

const StyledNetworkStatus = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  position: sticky;
  z-index: 100000000000000000000;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  color: white;
  transition-delay: 2s;

  &.not_connected {
    background-color: ${({theme}) => theme.palette.error.main};
    max-height: 2em;
  }

  &.connected {
    background-color: ${({theme}) => theme.palette.success.main};
  }

  &.connecting {
    background-color: ${({theme}) => theme.palette.warning.main};
    max-height: 2em;
  }
`


function ping(ip: string, isUp: Function, isDown: Function) {
    let ws: null | WebSocket = new WebSocket("ws://" + ip);
    ws.onerror = function (e) {
        ws?.close();
        ws = null;
    };
    setTimeout(function () {
        if (ws != null) {
            ws.close();
            isUp()
        } else {
            isDown();
        }
    }, 2000);
}

const checker = () => new Promise((res, rej) => {
    ping('8.8.4.4', () => res(true), () => res(false))
})

export const NetworkStatus = () => {

    const [retries, setRetries] = useState(0);
    const [online, setOnline] = useState(true)
    const network = useNetwork();
    const {
        data,
        error,
        isValidating: connecting,
        mutate,
    } = useSWR('online', checker, {
        refreshInterval: 60000,
    });

    useEffect(() => {
        if (!network.online) {
            mutate(false)
        }
    }, [network.online])

    useEffect(() => {
        let timeout: any;
        if (data) {
            setRetries(0)
            setOnline(true)
        } else {
            if (retries > 2) {
                setOnline(false)
            } else {
                setRetries(r => r + 1)
                timeout = setTimeout(() => mutate(undefined), 2000)
            }
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [data])

    const status = useMemo(() => {
        if (online && network.online) {
            return 'connected'
        }

        if (connecting) {
            return 'connecting'
        }

        return 'not_connected'
    }, [connecting, online, network.online])
    return <StyledNetworkStatus className={`${status}`}>
        {<Locale>{`network_status.${status}`}</Locale>}
        {status === 'connecting' && <CircularProgress color={"inherit"} size={20}/>}
    </StyledNetworkStatus>
}
