import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {css} from "@emotion/react";
import {darkNeomorph, lightNeomorph} from "../../Providers/Theme";


export const sidepanelWidth = 275;


export const StyledDashboard = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;

  & > main {
    flex: 1;

    & > .MuiAppBar-root {

      img {
        vertical-align: bottom;
        height: 2.75em;
      }
    }
  }

  .MuiDrawer-root {
    display: none;
    width: ${sidepanelWidth + 40}px;
    max-width: ${sidepanelWidth + 40}px;
    transition: ${({theme}) => theme.transitions.create('max-width')};
    position: sticky;
    height: 100vh;
    top: 0;
    padding: 20px;

    & > .MuiPaper-root {
      position: relative;
      //width: 100%;
      border: 0;
      border-radius: 50px;
      background: url(/pattern_2.svg) no-repeat bottom left,
      linear-gradient(to bottom, transparent, ${({theme}) => theme.palette.background.paper}),
      url(/pattern.svg);
      background-size: contain, cover, 150px;
      
      .MuiListItem-root {
        max-width: 240px;
        margin: 6px auto;
        border-radius: 30px;
        background: ${({theme}) => `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper})`};
        box-shadow: ${({theme}) => theme.palette.mode === 'dark' ? darkNeomorph : lightNeomorph};
      }

      .MuiListItemIcon-root {
        justify-content: center;
        transition: ${({theme}) => theme.transitions.create(['min-width', 'font-size', 'margin'])};
      }

      .MuiListItemText-root {
        color: ${({theme}) => theme.palette.text.primary};
        transition: ${({theme}) => theme.transitions.create(['flex'])};
        overflow: hidden;
        white-space: nowrap;
      }

      .active {
        background: ${({theme}) => `linear-gradient(${theme.palette.primary.main}30, ${theme.palette.primary.main}30), linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper})`};
      }

      .collapse-button {
        .MuiBox-root {
          transition: ${({theme}) => theme.transitions.create(['flex'])};
        }

        svg {
          transition: ${({theme}) => theme.transitions.create(['transform'])};
          margin: 0 20px;
        }
      }
    }
  }

  ${({theme}) => theme.breakpoints.up('md')} {
    .MuiDrawer-root {
      display: grid;
    }

    &.collapsed {
      .MuiDrawer-root {
        max-width: 110px;

        .MuiListItemIcon-root {
          margin: auto;
          min-width: 24px;

          &:not(.main) {
            font-size: 24px;
          }

          &.main {
            font-size: 18px;
          }
        }

        .MuiListItemText-root {
          //max-width: 0;
          flex: 0;
        }

        .collapse-button {
          .MuiBox-root {
            flex: 0 !important;
          }

          svg {
            transform-origin: center center;
            transform: rotate(180deg);
          }
        }
      }
    }

    & > main {
      & > .MuiAppBar-root {
        display: none;
      }
    }
  }
`

export const StyledSwipableDrawer = styled(SwipeableDrawer)`

  & > .MuiPaper-root {
    width: ${sidepanelWidth}px;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    border-radius: 0 0 50px 0;
    background: url(/pattern_2.svg) no-repeat bottom left,
    linear-gradient(to bottom, transparent, ${({theme}) => theme.palette.background.paper}),
    url(/pattern.svg),
    linear-gradient(to bottom,  ${({theme}) => theme.palette.background.paper}, ${({theme}) => theme.palette.background.paper})
  ;
    background-size: 100%, cover, 150px;
  }

  ${({theme}) => theme.breakpoints.up('md')} {
    display: none;
  }

  .MuiListItemText-root {
    color: ${({theme}) => theme.palette.text.primary};
  }
`
