export const intersect = <T extends string | number>(...arrays: T[][]) => {
    let finalArray = arrays.shift() || [];
    for (const array of arrays) {
        finalArray = finalArray.filter(i => array.includes(i))
    }

    return finalArray;
}

export const intersectObjectArrays = <T>(getCompareValue: (item: T) => T[keyof T], ...arrays: T[][]) => {
    // TODO
}
