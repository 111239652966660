import {SnackbarProvider} from "notistack";
import React from "react";
import Auth from "./Auth";
import {ThemeProvider} from "./Theme";
import LocaleProvider from "./LocaleProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {Location} from 'history';
import {SWRConfig} from "swr";
import {DialogOnDemandProvider} from "./DialogOnDemand";
import {ProgressBar} from "./ProgressBar";

const cacheMap = new Map<string, any>(JSON.parse(localStorage.cacheMap || '[]'))
let timeout: any;
const updateCacheMap = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
        localStorage.cacheMap = JSON.stringify(Array.from(cacheMap.entries()));
    }, 60000)
};

const cacheProvider = () => {
    return ({
        get(key: string) {
            return cacheMap.get(key)
        },
        set(key: string, value: any) {
            cacheMap.set(key, value);
            updateCacheMap();
        },
        delete(key: string) {
            cacheMap.delete(key)
            updateCacheMap();
        }
    })
}
export default (function ({children}) {
    return <>
        <ProgressBar>
            <SWRConfig value={{provider: cacheProvider}}>
                <LocaleProvider defaultLocale={"ar"}>
                    <Auth>
                        <ThemeProvider>
                            <SnackbarProvider>
                                <DialogOnDemandProvider>
                                    {children}
                                </DialogOnDemandProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </Auth>
                </LocaleProvider>
            </SWRConfig>
        </ProgressBar>
    </>
}) as React.FC
