import useSWR, {SWRConfiguration} from 'swr';
import {useMemo} from "react";
import addParamObjectToUrl from "../utils/addParamObjectToUrl";

const fetcher = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
    .then(async (r: Response) => {
        if (r.status >= 400) {
            throw ({_response: r, ...(await r.json())})
        }

        return await r.json()
    })

export default function <R = Record<any, any>, E = Record<any, any> & { _response: Response }>
(input: RequestInfo, init?: RequestInit & { params?: Record<any, any> }, swrConfig: SWRConfiguration = {}) {
    const _input = useMemo(() => {
        if (typeof input === 'string') {
            return addParamObjectToUrl(input, init?.params || {})
        } else {
            return {
                ...input,
                url: addParamObjectToUrl(input.url, init?.params || {})
            }
        }
    }, [init?.params, input])
    const swr = useSWR<R, E & { _response: Response }>([_input, init], fetcher, {
        ...swrConfig,
        refreshInterval: swrConfig.refreshInterval || 60000
    })
    return {...swr, loading: !(swr.data || swr.error) && swr.isValidating}
}
