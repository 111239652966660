import createTheme from '@mui/material/styles/createTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'
import type {PaletteMode, Theme, ThemeOptions} from "@mui/material";
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache'
import useMeta from "../hooks/Api/useMeta";

export const lightNeomorph = '2px 4px 3px #c9c9c9, -2px -4px 5px #ffffff'
export const darkNeomorph = '2px 4px 3px #202020, -2px -4px 5px #363636'
export const lightNeomorphIn = 'inset 2px 4px 7px #c9c9c9, inset -2px -4px 7px #ffffff'
export const darkNeomorphIn = 'inset  2px 4px 7px #202020,inset  -2px -4px 7px #363636'

function boxShadowToFilterDropShadow(value: string) {
    return value.split(',').map(s => `drop-shadow(${s})`).join(' ')
}

function bgColorLg(color?: string) {
    return `linear-gradient(${color}, ${color})`
}

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
});

const RTL: React.FC = (props) => {
    return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}


export const defaultThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#E0C881'
        },
        secondary: {
            main: "rgb(66,177,200)"
        }
        //     mode: 'dark'
    },
    typography: {
        fontFamily: '"Tajawal", "Montserrat", sans-serif'
    },
    direction: 'rtl',
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '15px',
                    transition: 'initial'
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-root:not(.Mui-focused) fieldset': {
                        border: '0',
                    },
                    'fieldset legend': {
                        // display: 'none',
                        width: '0 !important'
                    },
                    '.MuiInputLabel-shrink': {
                        transform: 'translate(12px, 0px) scale(0.75)',
                    }
                }
            }
        }
    }
};


type ThemeHook = {
    themeOptions: ThemeOptions;
    setThemeOptions: React.Dispatch<React.SetStateAction<ThemeOptions>>;
    theme: Theme;
    readonly themeMode: PaletteMode;
    toggleThemeMode: (mode?: PaletteMode) => void;
    isDarkMode: boolean,
    isLightMode: boolean,
}

function useThemeSetup(): ThemeHook {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
    const [mode, _setMode] = useState<PaletteMode>(defaultThemeOptions?.palette?.mode || localStorage.themeMode);
    const setMode = useCallback<React.Dispatch<React.SetStateAction<PaletteMode>>>((m) => {
        let newMode = m instanceof Function ? m(mode) : m;
        _setMode(newMode);
        localStorage.themeMode = newMode;
    }, [mode])
    const [themeOptions, setThemeOptions] = useState(defaultThemeOptions);
    const [theme, setTheme] = useState(createTheme(themeOptions));
    const toggleMode = useCallback((m: PaletteMode | any = mode === 'light' ? 'dark' : mode === 'dark' ? undefined : 'light') => setMode(m), [mode])

    const isDarkMode = useMemo(() => mode ? mode === 'dark' : prefersDarkMode, [prefersDarkMode, mode]);
    const isLightMode = useMemo(() => mode === 'light', [mode]);

    useEffect(() => {
        themeOptions.palette = themeOptions?.palette ?? {};
        themeOptions.palette.mode = isDarkMode ? 'dark' : 'light';
        themeOptions.palette.background = themeOptions?.palette.background ?? {};
        themeOptions.palette.background.paper = themeOptions.palette.background.default = isDarkMode ? '#2b2b2b' : '#f3f2f4'
        // themeOptions.palette.background.paper = isDarkMode ? '#2b2b2b' : '#eeedef'
        themeOptions.components = {
            ...themeOptions.components,
            MuiPaper: {
                ...themeOptions.components?.MuiPaper,
                styleOverrides: {
                    ...themeOptions.components?.MuiPaper?.styleOverrides,
                    root: {
                        ...(themeOptions.components?.MuiPaper?.styleOverrides?.root as any),
                        background: themeOptions?.palette?.background?.paper,
                        boxShadow: isDarkMode ? darkNeomorph : lightNeomorph,
                    }
                }
            },
            MuiModal: {
                styleOverrides: {
                    root: {
                        ' .MuiPaper-root': {
                            boxShadow: theme.shadows["1"]
                        },
                        '.MuiBackdrop-root': {
                            backdropFilter: 'blur(5px)'
                        }
                    }
                }
            },
            MuiFormControl: {
                ...(themeOptions.components?.MuiFormControl),
                styleOverrides: ({
                    ...(themeOptions.components?.MuiFormControl?.styleOverrides),
                    root: (props) => {
                        const inNeo = isDarkMode ? darkNeomorphIn : lightNeomorphIn
                        const outNeo = isDarkMode ? darkNeomorph : lightNeomorph

                        return {
                            ...(themeOptions.components?.MuiFormControl?.styleOverrides?.root as any),
                            '.MuiOutlinedInput-root:not(.Mui-focused) fieldset': {
                                border: '0',
                            },
                            'fieldset legend': {
                                // display: 'none',
                                width: '0 !important'
                            },
                            '.MuiInputLabel-shrink': {
                                transform: 'translate(12px, 2px) scale(0.75)',
                            },
                            '.MuiOutlinedInput-root': {
                                boxShadow: props.neoMorph === 'out' ? outNeo : inNeo,
                                background: `${bgColorLg((isDarkMode ? `rgba(255, 255, 255, 0.025)` : `rgba(0, 0, 0, 0.025)`))}, ${bgColorLg(themeOptions?.palette?.background?.default)}`,
                                borderRadius: '20px !important',
                            }
                        }
                    },
                }),
            },
        }

        setTheme(createTheme(themeOptions));
    }, [mode, themeOptions, prefersDarkMode])

    useEffect(() => {
        document.documentElement.dir = theme.direction
    }, [theme])
    return {
        toggleThemeMode: toggleMode,
        isDarkMode,
        isLightMode,
        themeMode: mode,
        themeOptions,
        setThemeOptions,
        theme,
    }
}


const ThemeContext = createContext<ThemeHook>({
    toggleThemeMode: mode => {
    },
    themeMode: defaultThemeOptions?.palette?.mode || 'light',
    isDarkMode: defaultThemeOptions?.palette?.mode === 'dark',
    isLightMode: defaultThemeOptions?.palette?.mode === 'light',
    themeOptions: defaultThemeOptions,
    setThemeOptions: theme => {
    },
    theme: createTheme(defaultThemeOptions),
});

const ThemeProvide: React.FC = (props) => {
    const theme = useThemeSetup();
    const {data: meta} = useMeta()


    return (
        <ThemeContext.Provider value={theme}>
            <ThemeProvider theme={theme.theme}>
                <RTL>
                    <CssBaseline/>
                    <style>
                        {`
                          body {
                            background: ${theme.theme.palette.background.default};
                            color: ${theme.theme.palette.text.primary};
                            font-family: ${theme.theme.typography.fontFamily} !important;
                          }
                          
                          .logo-image {
                            filter: ${boxShadowToFilterDropShadow(theme.isDarkMode ? darkNeomorph : lightNeomorph)};
                            color: ${theme.theme.palette.primary.main};
                            object-position: center center;
                            object-fit: contain;
                          }
                          #nprogress .bar, #nprogress .spinner {
                            z-index: 10000000000000000000000000000;
                          }
                          #nprogress .bar {
                            background: ${theme.theme.palette.primary.main};
                            height: 5px
                          }
                          
                          #nprogress .peg {
                            box-shadow: 0 0 10px ${theme.theme.palette.primary.main}, 0 0 5px ${theme.theme.palette.primary.main};
                          }
                          
                          #nprogress .spinner-icon  {
                            border-top-color: ${theme.theme.palette.primary.main} !important;
                            border-left-color: ${theme.theme.palette.primary.main} !important;
                          }
                        `}
                    </style>
                    {meta && props.children}
                </RTL>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

const useTheme = () => useContext(ThemeContext);

export {useTheme, ThemeProvide as ThemeProvider};
