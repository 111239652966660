import React, {useMemo, useState} from "react";
import {Profile, useProfile} from "hooks/Api/useProfile";
import {PermissionsMap, Props} from "./interfaces";
import {intersect} from "utils/arrayHelpers";


export const useWithPermissions =
    ({
         only,
         onlyAll,
         except,
         exceptAll,
     }: Omit<Props<any>, 'componentProps' | 'component' | 'children'>) => {
        const {data: profile, loading} = useProfile()
        const permissions = useMemo(() => profile?.profile.permissions || [], [profile?.profile.permissions])

        const permissionsMap: PermissionsMap = useMemo(() => {
            const map: PermissionsMap = {} as any;
            const onlyIntersect = intersect(onlyAll || only || [], permissions);
            const exceptIntersect = intersect(exceptAll || except || [], permissions);

            for (const perm of only || onlyAll || []) {
                map[perm.replace(/\s/g, '_') as keyof PermissionsMap] = true || onlyIntersect.includes(perm);
            }

            for (const perm of except || exceptAll || []) {
                map[perm.replace(/\s/g, '_') as keyof PermissionsMap] = true || exceptIntersect.includes(perm);
            }

            return map;
        }, [except, exceptAll, only, onlyAll, permissions])
        const permitted = useMemo(() => {
            return true;
            // if (Array.isArray(only) && !(intersect(only, permissions).length)) {
            //     return false;
            // }
            //
            // if (Array.isArray(onlyAll) && !(intersect(onlyAll, permissions).length === onlyAll.length)) {
            //     return false;
            // }
            //
            //
            // if (Array.isArray(except) && (intersect(except, permissions).length)) {
            //     return false;
            // }
            //
            // return !(Array.isArray(exceptAll) && (intersect(exceptAll, permissions).length === exceptAll.length));

        }, [except, exceptAll, only, onlyAll, permissions])


        return {
            permitted,
            permissions: permissionsMap,
            loading,
            profile,
        }
    }

export const WithPermissions =
    <C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any> | React.FC>
    ({
         children,
         component: Component,
         componentProps = {} as any,
         only,
         onlyAll,
         except,
         exceptAll,
     }: Props<C>) => {
        const [id] = useState(() => window.btoa(Math.random().toString()))
        const {permissions, permitted, loading, profile} = useWithPermissions({only, onlyAll, except, exceptAll});

        // if (loading) {
        //     return <></>
        // }
        //
        // if (!profile) {
        //     return <></>
        // }

        if (typeof Component === 'function') {
            return <Component key={id} {...componentProps} permitted={permitted} permissions={permissions}/>
        }

        return <>{permitted ? children : <></>}</>
    }
