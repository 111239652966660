import {getVendorID} from "./vendorID";

export default function (url: string | URL = '', params: Record<any, any> | URLSearchParams) {
    let URLParams: URLSearchParams;
    const appendedURLParams = new URLSearchParams(params);
    try {
        URLParams = new URL(url).searchParams;
    } catch (e) {
        return url;
    }

    if (!URLParams) {
        URLParams = new URLSearchParams();
    }

    for (const [key, value] of Array.from(appendedURLParams.entries())) {
        URLParams.append(key, value)
    }

    return `${url.toString().split('?').shift()}?${URLParams.toString()}`;
}
