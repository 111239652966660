export const parseFiltersParams = (params: Record<any, any>) => {
    const filters = [];
    if (typeof params.filters === 'string') {
        filters.push(params.filters)
    } else if (typeof params.filters === 'object') {
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined) {
                filters.push(`${key}:${value}`)
            }
        }
    }

    delete params.filters;

    for (const [key, value] of Object.entries(params)) {
        const f = /^filters\[([a-zA-Z0-9_.-]+)\]$/.exec(key)
        if (f) {
            if (value !== undefined) {
                filters.push(`${f[1]}:${value}`)
            }
            delete params[key];
        }
    }

    if (filters.length) {
        params.filters = filters.join(',')
    }

    return params;
}
