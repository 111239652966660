import React, {createContext, useContext, useEffect, useMemo, useState} from "react";

// import jwtDecode from "jwt-decode";


interface IContext {
    token: string;
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Context = createContext<IContext>({} as IContext);

const Auth: React.FC = ({children}) => {
    const [token, setToken] = useState(localStorage.token);

    // const decodedToken = useMemo(() => {
    //     if (token) {
    //         return jwtDecode(token);
    //     }
    //     return undefined;
    // }, [token])

    useEffect(() => {
        if (token) {
            localStorage.token = token;
        } else {
            localStorage.removeItem('token')
        }
    }, [token])
    return <Context.Provider value={{token, setToken}}>
        {children}
    </Context.Provider>
}

export default Auth;

export const useAuth = () => useContext(Context)


export interface AuthCheckProps {
    otherwise?: React.ReactChild | React.ReactChildren
}

export const AuthOnly:
    React.FC<AuthCheckProps> = ({children, otherwise}) => {
    const {token} = useAuth();
    return <>
        {!!token ? children : otherwise}
    </>
}

export const GuestOnly:
    React.FC<AuthCheckProps> = ({children, otherwise}) => {
    const {token} = useAuth();
    return <>
        {!token ? children : otherwise}
    </>
}
