const flatten = (value: any, ref: Record<any, any> = {}, keys: string[] = []) => {
    if (typeof value === 'object' && !(value instanceof Blob)) {
        for (const [k, v] of Object.entries(value)) {
            flatten(v, ref, [...keys, k])
        }
    } else {
        ref[keys.shift() + keys.map(k => `[${k}]`).join('')] = value;
    }
    return ref;
}

export const toFormData = (json: Record<any, any>) => {
    const flattened = flatten(json)
    console.log(flattened, json);
    const formData = new FormData();
    for (const [k, v] of Object.entries(flattened)) {
        if (v !== undefined) {
            formData.set(k, v);
        }
    }
    return formData;
}
