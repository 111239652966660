import React, {useEffect, useMemo} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {Meta} from "../../hooks/Api/useMeta";
import {Locale, useLocale} from "../../Providers/LocaleProvider";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAdjust} from "@fortawesome/free-solid-svg-icons/faAdjust";
import {faSun} from "@fortawesome/free-solid-svg-icons/faSun";
import {faMoon} from "@fortawesome/free-solid-svg-icons/faMoon";
import useMediaQuery from "@mui/material/useMediaQuery";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {DrawerMenuItem} from "./DrawerMenuItem";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {sidepanelWidth} from "./StyledDashboard";
import {DrawerList} from "./DrawerList";
import {useTheme} from "../../Providers/Theme";
import {NavLink} from "react-router-dom";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {WithPermissions} from "../WithPermissions";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {faCogs} from "@fortawesome/free-solid-svg-icons/faCogs";
import {faScroll} from "@fortawesome/free-solid-svg-icons/faScroll";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";
import {ReactComponent as Logo} from 'logo.svg'
import {faAd} from "@fortawesome/free-solid-svg-icons/faAd";

export const DrawerContent: React.FC<{
    toggleThemeMode: () => any,
    themeMode: 'dark' | 'light' | undefined,
    onLogout: () => any,
    meta?: Meta,
    toggleCollapsed?: () => any,
    collapsed: boolean;
}> =
    ({
         meta,
         collapsed,
         toggleThemeMode,
         themeMode,
         onLogout,
         toggleCollapsed,
     }) => {
        const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
        const {theme, setThemeOptions, themeOptions} = useTheme();
        const mobile = useMediaQuery(theme.breakpoints.down('md'))
        const {locale, setLocale} = useLocale();

        useEffect(() => {
            setThemeOptions({
                ...themeOptions,
                direction: locale === 'ar' ? 'rtl' : 'ltr'
            })
        }, [locale])

        const themeModeButton = useMemo(() => {
            switch (themeMode) {
                case 'light':
                    return {
                        icon: <FontAwesomeIcon icon={faSun}/>,
                        label: <Locale>theme.light</Locale>
                    }
                case 'dark':
                    return {
                        icon: <FontAwesomeIcon icon={faMoon}/>,
                        label: <Locale>theme.dark</Locale>
                    }
                default:
                    return {
                        icon: <FontAwesomeIcon icon={faAdjust}/>,
                        label: <>
                            {prefersDarkMode ? <Locale>theme.dark</Locale> :
                                <Locale>theme.light</Locale>} (<Locale>theme.system</Locale>)
                        </>
                    }
            }
        }, [themeMode, prefersDarkMode])

        return <>
            <Box component={Logo} className={"logo-image"} maxHeight={"175px"} my={"50px"} p={1}
                 width={"100%"}/>
            <DrawerMenuItem
                icon={<FontAwesomeIcon icon={faHome}/>}
                label={<Locale>home.title</Locale>}
                component={NavLink}
                to={"/"} end/>
            <WithPermissions only={["update banner"]}>
                <DrawerMenuItem
                    icon={<FontAwesomeIcon icon={faAd}/>}
                    label={<Locale>banners.title</Locale>}
                    component={NavLink}
                    to={"/banners"}/>
            </WithPermissions>
            <WithPermissions only={["read category"]}>
                <DrawerMenuItem
                    icon={<FontAwesomeIcon icon={faLayerGroup}/>}
                    label={<Locale>categories.title</Locale>}
                    component={NavLink}
                    to={"/categories"}/>
            </WithPermissions>
            <WithPermissions only={["read prayer"]}>
                <DrawerMenuItem
                    icon={<FontAwesomeIcon icon={faScroll}/>}
                    label={<Locale>prayers.title</Locale>}
                    component={NavLink}
                    to={"/prayers"}/>
            </WithPermissions>
            {/*<WithPermissions only={["read profile"]}>*/}
            {/*    <DrawerMenuItem*/}
            {/*        icon={<FontAwesomeIcon icon={faUsers}/>}*/}
            {/*        label={<Locale>users.title</Locale>}*/}
            {/*        component={NavLink}*/}
            {/*        to={"/users"}/>*/}
            {/*</WithPermissions>*/}
            <DrawerMenuItem
                icon={<FontAwesomeIcon icon={faCogs}/>}
                label={<Locale>settings.title</Locale>}
                component={NavLink}
                to={"/settings"}/>
            <DrawerMenuItem
                icon={themeModeButton.icon}
                label={themeModeButton.label}
                button
                onClick={() => toggleThemeMode()}/>
            {/*<PopupState variant="popover" popupId="demo-popup-menu">*/}
            {/*    {(popupState) => (*/}
            {/*        <React.Fragment>*/}
            {/*            <DrawerMenuItem*/}
            {/*                collapsed={collapsed && !mobile}*/}
            {/*                icon={<FontAwesomeIcon icon={faGlobe}/>}*/}
            {/*                label={<Locale>locale.language</Locale>}*/}
            {/*                button*/}
            {/*                {...bindTrigger(popupState)}*/}
            {/*            />*/}
            {/*            <Menu {...bindMenu(popupState)}*/}
            {/*                  variant={"selectedMenu"}*/}
            {/*                  anchorOrigin={{*/}
            {/*                      vertical: 'top',*/}
            {/*                      horizontal: 'right',*/}
            {/*                  }}*/}
            {/*                  PaperProps={{*/}
            {/*                      sx: {*/}
            {/*                          width: {*/}
            {/*                              xs: `${sidepanelWidth}px`,*/}
            {/*                              md: collapsed ? 'auto' : `${sidepanelWidth}px`*/}
            {/*                          }*/}
            {/*                      }*/}
            {/*                  }}*/}
            {/*                  transformOrigin={{*/}
            {/*                      vertical: 'top',*/}
            {/*                      horizontal: 'right',*/}
            {/*                  }}>*/}
            {/*                <MenuItem selected={locale === 'ar'} onClick={() => {*/}
            {/*                    setLocale('ar')*/}
            {/*                    popupState.close()*/}
            {/*                }}>العربية</MenuItem>*/}
            {/*                <MenuItem selected={locale === 'en'} onClick={() => {*/}
            {/*                    setLocale('en')*/}
            {/*                    popupState.close()*/}
            {/*                }}>English</MenuItem>*/}
            {/*            </Menu>*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</PopupState>*/}
            <DrawerMenuItem
                collapsed={collapsed}
                icon={<FontAwesomeIcon icon={faSignOutAlt}/>}
                label={<Locale>auth.logout</Locale>}
                button
                onClick={onLogout}
            />
            <Box flex={1}/>
            {/*{*/}
            {/*    toggleCollapsed &&*/}
            {/*    <Button*/}
            {/*        className="collapse-button"*/}
            {/*        size={"large"}*/}
            {/*        color={"inherit"}*/}
            {/*        onClick={toggleCollapsed}>*/}
            {/*        <Box flex={1}/>*/}
            {/*        <FontAwesomeIcon size={"lg"} icon={faChevronRight}/>*/}
            {/*    </Button>*/}
            {/*}*/}
        </>
    }
