import useApiFetchV1, {useApiFetchV1Request} from "../useApiFetchV1";

export module Profile {


    export interface Vendor {
        id: string;
        name: string;
    }

    export interface Profile {
        id: string;
        avatar: string;
        name: string;
        gender: 'male' | 'female';
        birthdate: Date;
        invoice_reference: string;
        last_order: number;
        is_notifiable: boolean;
        is_disabled: boolean;
        os_type?: any;
        running_on?: any;
        last_known_location?: any;
        app_version?: any;
        created_at: Date;
        updated_at: Date;
        vendor: Vendor;
        roles: string[];
        addresses: any[];
        permissions: Permission[];
    }

    export type Permission =
        | "delete delivery_point"
        | "update holiday"
        | "read category"
        | "read prayer"
        | "read schedule"
        | "read role"
        | "update banner"
        | "update unit"
        | "delete review"
        | "update faq"
        | "read contact"
        | "read banner"
        | "update area"
        | "read currency"
        | "create order"
        | "update order"
        | "delete notification"
        | "read unit"
        | "read token"
        | "update profile"
        | "create token"
        | "read country"
        | "update token"
        | "update delivery_point"
        | "create address"
        | "create price_modifier"
        | "delete banner"
        | "update review"
        | "delete schedule"
        | "read user"
        | "create review"
        | "create activity"
        | "delete price_modifier"
        | "delete contact"
        | "delete category"
        | "read payment_method"
        | "read holiday"
        | "read item"
        | "update cart"
        | "update notification"
        | "read price_modifier"
        | "read viewable"
        | "read address"
        | "read image"
        | "update transaction"
        | "read notification"
        | "create contact"
        | "read vendor"
        | "create unit"
        | "create area"
        | "create transaction"
        | "create schedule"
        | "update price_modifier"
        | "create payment_method"
        | "create notification"
        | "create cart"
        | "update payment_method"
        | "create image"
        | "create holiday"
        | "read faq"
        | "update item"
        | "create banner"
        | "read activity"
        | "delete unit"
        | "read review"
        | "delete image"
        | "update vendor"
        | "create category"
        | "read transaction"
        | "delete faq"
        | "read permission"
        | "update schedule"
        | "create item"
        | "update contact"
        | "update address"
        | "read order"
        | "read profile"
        | "update image"
        | "read area"
        | "read delivery_point"
        | "update category"
        | "read city"
        | "update city"
        | "create faq"
        | "create delivery_point"
        | "read cart";

    export interface RootObject {
        id: string;
        phone: string;
        email: string;
        created_at: Date;
        updated_at: Date;
        profile: Profile;
    }

}


export const useProfile = () => {
    return useApiFetchV1<Profile.RootObject>('profile', {params: {include: 'cities'}})
}


export const useProfileUpdate = () => {
    return  useApiFetchV1Request<Profile.RootObject>('profile')
}
