import React, {useState} from 'react'
import {Outlet} from 'react-router-dom'
import {StyledDashboard, StyledSwipableDrawer} from "./StyledDashboard";
import Drawer from "@mui/material/Drawer";
import {AppBar, IconButton, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import useMeta from "../../hooks/Api/useMeta";
import {DrawerContent} from "./DrawerContent";
import {useTheme} from "../../Providers/Theme";
import {useAuth} from "../../Providers/Auth";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import Box from "@mui/material/Box";
import {ReactComponent as Logo} from "../../logo.svg";


export default (function () {

    const [open, setOpen] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const {toggleThemeMode, themeMode} = useTheme();
    const {setToken} = useAuth()
    const {data: meta} = useMeta()

    return <>
        <StyledDashboard className={`${collapsed && 'collapsed'}`}>
            <Drawer
                variant="permanent"
            >
                <DrawerContent
                    collapsed={collapsed}
                    meta={meta}
                    themeMode={themeMode}
                    onLogout={() => setToken(undefined)}
                    toggleThemeMode={toggleThemeMode}
                    toggleCollapsed={() => setCollapsed(!collapsed)}
                />
            </Drawer>
            <StyledSwipableDrawer
                keepMounted
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}>
                <DrawerContent
                    collapsed={collapsed}
                    meta={meta}
                    themeMode={themeMode}
                    onLogout={() => setToken(undefined)}
                    toggleThemeMode={toggleThemeMode}
                />
            </StyledSwipableDrawer>
            <main>
                <AppBar position="sticky" color="inherit">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setOpen(true)}
                            sx={{mr: 2, display: {md: 'none'}}}
                        >
                            <FontAwesomeIcon icon={faBars}/>
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            <Box component={Logo} className={"logo-image"} />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Outlet/>
            </main>
        </StyledDashboard>
    </>
}) as React.FC
