import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import {Dialog, DialogProps} from "@mui/material";


type DialogState = {
    content: React.ReactChild | React.ReactChildren;
    props?: Omit<DialogProps, 'open'>;
}

const context = createContext({
    openDialog: (content: React.ReactChild | React.ReactChildren, props?: Omit<DialogProps, 'open'>, id?: string) => {
        console.error('DialogOnDemandProvider not found')
    },
    closeDialog: (id: string) => {
        return;
    }
})


export const DialogOnDemandProvider: React.FC = ({children}) => {

    const [dialogs, setDialogs] = useState<Record<string, DialogState>>({})

    const closeDialog = useCallback((id: string) => {
        setDialogs(d => {
            console.log(d[id])
            delete d[id];
            console.log(d[id])
            return {
                ...d,
            };
        })
    }, [])

    const openDialog = useCallback((content: React.ReactChild | React.ReactChildren, props?: Omit<DialogProps, 'open'>, id: string = Date.now().toString(16)) => {
        setDialogs(d => {
            return {
                ...d,
                [id]: {
                    content,
                    props,
                }
            };
        })
        return id;
    }, [])


    const dialogEntries = useMemo(() => Object.entries(dialogs), [dialogs])


    return <context.Provider value={{openDialog, closeDialog}}>
        {/*{dialogContent &&*/}
        {/*<Dialog {...dialogProps} onClose={dialogProps?.onClose || closeDialog} open>{dialogContent}</Dialog>}*/}

        {dialogEntries?.map(([id, dialog]) =>
            <Dialog key={id}
                    {...dialog.props}
                    onClose={dialog.props?.onClose || (() => closeDialog(id))}
                    open
            >
                {dialog.content}
            </Dialog>
        )}
        {children}
    </context.Provider>
}


export const useDialogOnDemand = () => {
    const dialogOnDemand = useContext(context);
    const [id, setId] = useState<string>()

    const openDialog = useCallback((content: React.ReactChild | React.ReactChildren, props?: Omit<DialogProps, 'open'>, id: string = Date.now().toString(16)) => {
        setId(id)
        dialogOnDemand.openDialog(content, props, id)
        return {
            close: () => dialogOnDemand.closeDialog(id)
        }
    }, [dialogOnDemand])

    const closeDialog = (dialogId = id) => {
        if (typeof dialogId === "string") {
            dialogOnDemand.closeDialog(dialogId)
        }
    }


    return {
        openDialog,
        closeDialog,
        id,
    }
}
