import React, {lazy, useEffect} from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {useLocale} from "./Providers/LocaleProvider";
import {useTheme} from "./Providers/Theme";
import {AuthOnly, GuestOnly} from "./Providers/Auth";
import Dashboard from './components/Dashboard';
import {WithPermissions} from "./components/WithPermissions";
import {NetworkStatus} from "./components/NetworkStatus";
import {useProgressBar} from "./Providers/ProgressBar";

const Login = lazy(() => import('./views/Login'))
const Home = lazy(() => import('./views/Home'))
const Settings = lazy(() => import('./views/Settings'))
const Categories = lazy(() => import('./views/Categories'))
const Prayers = lazy(() => import('./views/Prayers'))
const EditPrayer = lazy(() => import('./views/Prayers/EditPrayer'))
const Banners = lazy(() => import('./views/Banners'))
// const Users = lazy(() => import('./views/Users'))


const LazyLoadingSuspense = () => {
    const progressBar = useProgressBar()

    useEffect(() => {
        let p = progressBar.start()

        return () => {
            p.done()
        }
    }, [])
    return <>loading...</>
}


function App() {

    const {locale} = useLocale();
    const {setThemeOptions} = useTheme();

    useEffect(() => {
        setThemeOptions(o => ({...o, direction: locale === 'ar' ? 'rtl' : 'ltr'}))
    }, [locale])

    return (
        <>
            <NetworkStatus/>
            <React.Suspense fallback={<LazyLoadingSuspense/>}>
                <Routes>
                    <Route element={<GuestOnly children={<Outlet/>} otherwise={<Navigate to={"/"}/>}/>}>
                        <Route path={"/login"} element={<Login/>}/>
                    </Route>
                    <Route
                        path={"/"}
                        element={
                            <AuthOnly children={<Dashboard/>} otherwise={<Navigate to={"/login"}/>}/>
                        }>
                        <Route path={"/"} element={<Home/>}/>
                        {/*<Route path={"/users"} element={<WithPermissions children={<Users key={"/users"}/>}*/}
                        {/*                                                 only={["update profile"]}/>}/>*/}

                        <Route path={"/banners"} element={<WithPermissions children={<Banners key={"/banners"}/>}
                                                                           only={["update banner"]}/>}/>
                        <Route path={"/categories"}
                               element={<WithPermissions children={<Categories key={"/categories"}/>}
                                                         only={["read category"]}/>}/>
                        <Route path={"/prayers"} element={<WithPermissions children={<Prayers key={"/prayers"}/>}
                                                                           only={["read prayer"]}/>}/>
                        <Route path={"/prayers/:id"}
                               element={<WithPermissions children={<EditPrayer key={"/prayers/:id"}/>}
                                                         only={["read prayer"]}/>}/>

                        <Route path={"/settings"} element={<Settings/>}/>
                    </Route>
                </Routes>
            </React.Suspense>
        </>
    );
}

export default App;
