import {SWRConfiguration} from "swr";
import useFetchJSON from "./useFetchJSON";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAuth} from "../Providers/Auth";
import {toFormData} from "../utils/toFormData";
import {parseFiltersParams} from "../utils/parseFiltersParams";
import {useProgressBar} from "../Providers/ProgressBar";

export const isApiError = <E = any>(e: E): e is ApiError => {
    // @ts-ignore
    if (typeof e === 'object' && ApiErrors.includes(e.error?.type)) {
        return true
    }
    return false
}

export default function <R = Record<any, any>, E = Record<any, any>>(input: RequestInfo, init?: RequestInit & { params?: Record<any, any> }, swrConfig?: SWRConfiguration) {
    const {token} = useAuth();

    const _input = useMemo(() => {
        if (typeof input === 'string') {
            return (`${process.env.REACT_APP_API_V1_ENDPOINT}${input}`)
        } else {
            return {
                ...input,
                url: (`${process.env.REACT_APP_API_V1_ENDPOINT}${input.url}`)
            }
        }
    }, [input])


    const _init = useMemo(() => {
        const __init = init || {}
        __init.headers = __init.headers instanceof Headers ? Object.fromEntries(__init.headers.entries()) : __init.headers
        __init.headers = {...(__init.headers || {}), 'x-api-key': process.env.REACT_APP_API_KEY as string}

        if (token) {
            __init.headers = {...(__init.headers || {}), 'Authorization': `Bearer ${token}`}
        }

        if (__init.params) {
            __init.params = parseFiltersParams(__init.params)
        }

        return __init;
    }, [init, token])

    return useFetchJSON<R, ApiError<E>>(_input, _init, swrConfig)
}


export const useApiFetchV1Request = <R = Record<any, any>, E = Record<any, any>>(input?: RequestInfo) => {
    const [result, setResult] = useState<R>()
    const [error, setError] = useState<ApiError<E>>()
    const [loading, setLoading] = useState(false);
    const [abortController, setAbortController] = useState(() => new AbortController())
    const {token} = useAuth();
    const cancel = useCallback(() => {
        abortController.abort();
        setAbortController(new AbortController())
    }, [abortController])


    const progressBar = useProgressBar();
    useEffect(() => () => cancel(), [])


    const request = useCallback((init?: RequestInit & { path?: string; json?: Record<any, any>, asFormData?: boolean }) => {
        setLoading(true);
        setResult(undefined)
        setError(undefined)
        let _input = JSON.parse(JSON.stringify(input))
        if (typeof _input === 'string') {
            _input = (`${process.env.REACT_APP_API_V1_ENDPOINT}${_input}${init?.path || ''}`)
        } else {
            _input = {
                ..._input,
                url: (`${process.env.REACT_APP_API_V1_ENDPOINT}${_input.url}${init?.path || ''}`)
            }
        }


        const _init = init || {}
        _init.headers = (_init.headers instanceof Headers ? Object.fromEntries(_init.headers.entries()) : _init.headers) as any
        _init.signal = _init.signal || abortController.signal;
        _init.headers = {...(_init.headers || {}), 'x-api-key': process.env.REACT_APP_API_KEY as string}

        if (token) {
            // @ts-ignore
            _init.headers['Authorization'] = `Bearer ${token}`;
        }

        if (_init?.json) {

            if (_init.asFormData) {
                _init.body = toFormData(_init.json)
            } else {
                try {
                    _init.body = JSON.stringify(_init.json);
                    // @ts-ignore
                    _init.headers[`Content-Type`] = 'application/json'
                } catch (e) {
                }
            }
        }

        let progress = progressBar.start();
        return fetch(_input, _init)
            .then(async (r: Response) => {
                setLoading(false)
                if (r.status >= 400) {
                    throw r
                }
                const data = await r.json() as R;
                setResult(data);
                return data;
            })
            .catch(async (e: Response) => {
                setLoading(false)
                let err = {_response: e} as ApiError<E>;
                if (/application\/json/.test(e.headers?.get('content-type') || e.headers?.get('Content-Type') || '')) {
                    err = {_response: e, ...(await e.json())}
                }
                setError(err)
                throw (err)
            }).finally((() => {
                progress.done();
            }))
    }, [abortController.signal, input, token])

    return {
        result,
        error,
        loading,
        request,
        cancel
    }
}

export type ApiError<E = Record<any, any>> = {
    _response: Response
    error:
        | ApiErrorModalValidation
        | ApiErrorUniqueViolation
} & E


export const ApiErrors = [
    'MulterError',
    'ValidationError',
    'NotFoundError',
    'UniqueViolationError',
    'NotNullViolationError',
    'ForeignKeyViolationError',
    'CheckViolationError',
    'DataError',
    'DBError',
    'FileValidationError',
    'TokenExpiredError',
    'UnauthorizedAccess',
    'Unauthenticated',
    'InvalidCredentials',
    'MismatchError',
    'ModelValidation',
    'RelationExpression',
    'UnallowedRelation',
    'InvalidGraph',
    'InvalidInput'
]

export type ApiErrorTypes = typeof ApiErrors[number]

export type ApiErrorModalValidation = {
    type: 'ModelValidation',
    message: string;
    data: {
        [field: string]: ({
            keyword: string;
            message: string;
            params: any
        })[]
    }
}


export type ApiErrorUniqueViolation = {
    type: 'UniqueViolation',
    message: string;
    data: {
        columns: string[]
    }
}
